

export default {
  name: 'default',
  data() {
    return {

    }
  },
  computed: {

  },
  watch: {

  },
  methods:{

  }
};
